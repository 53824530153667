@import "../../styles/_colors";

.home-new {
    @media (max-width: $breakpoint-md) {
        .main-container {
            .main-title {
                // font-size: 2.2em;
            }
            .heading2 {
                // font-size: 1.1em;
            }
        }
    }
    .main-container {
        text-align: center;

        .heading2 {
            font-family: $font-default;
            font-size: 1.1em;
            font-weight: 400;
            line-height: 1.5em;
            margin-top: 0.8em;
            margin-bottom: 1em;
        }
        .portrait-image {
            width: 100%;
        }
    }
    hr.hr-default {
        margin-top:0.4em;
        margin-bottom:0.2em;
        // background-color: rgba( #000, 0);
    }
    .what-i-do {
        // .what-i-do-child:nth-child(even) {
        //     margin-left: 3em;
        // }
        .what-i-do-title{
            // color: $color-theme;
            // text-shadow: 6px 3px $color-theme;
        }
    }
    
    hr.hr-theme {
        margin-left: 0;
        margin-top: 0.5em;
        width: 8em;
        background-color: $color-theme;
        border: none;
        height: 0.3em;
    }
    @media (min-width: $breakpoint-md) {
        .main-container {
            .heading2 {
                width: 70%;
                font-size: 1.15em;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0.7em;
                margin-top: 0;
                line-height: 1.5em;    
            }
            .portrait-image {
                width: 50%;
            }
        }
    }
    @media (min-width: $breakpoint-lg) {
        .what-i-do {
            // width: 80%;
        }
    }
}