@import "../../styles/_colors.scss";

:root {
    --gutter: 20px;
}
.horizontal-scroll-new {
    // .slick-list {
    //     padding: 0 9em !important;
    // }

    .item:hover {
        background-color:rgba(0,0,0, 0.02);
        border-radius: 20px;
        .icon {
            color: $color-theme-dark;
            transform: scale(1.1, 1.1);
        }
        .label {
            color: $color-theme-dark;
        }
    }
    .item:active{
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
    .item {
        text-align: center;
        padding-top: 1em;
        padding-bottom: 1em;
        cursor: move;
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        .icon {
            color: $color-dark;
            padding-bottom:0;
            font-size: 2.5em;
            width:100%;
        }

        .label {
            transform: translateY(-0.5em);
            color: $color-grey-light;
            // font-size: 1.6em;
        }
    }

    @media (min-width: $breakpoint-md) {
        .paragraph-description{
            // width: 75%;  
            max-width: 756px;  
        }
    }

}

// .hs {
//     display: grid;
//     grid-gap: calc(var(--gutter) / 2);
//     // grid-template-columns: 10px repeat(6, calc(30% - var(--gutter) * 2)) 10px;
//     grid-template-rows: minmax(150px, 1fr);

//     overflow-x: scroll;
//     scroll-snap-type: x proximity;
//     padding-bottom: calc(.75 * var(--gutter));
//     margin-bottom: calc(-.5 * var(--gutter));
// }

// .hs:before,
// .hs:after {
//     content: '';
// }

// /* Hide scrollbar */
// .hide-scroll {
//     overflow-y: hidden;
//     // overflow-x: hidden;
//     margin-bottom: calc(-.1 * var(--gutter));
// }



// .item:hover {
//     background-color:black;
//     border-radius: 20px;
//     .icon {
//         transform: scale(1.1, 1.1);
//     }
//     .label {
//         color: white;
//     }
// }
// .item:active{
//     cursor: grabbing;
//     cursor: -moz-grabbing;
//     cursor: -webkit-grabbing;
// }
// .item {
//     text-align: center;
//     padding-top: 1em;
//     padding-bottom: 1em;
//     cursor: move;
//     cursor: grab;
//     cursor: -moz-grab;
//     cursor: -webkit-grab;
//     .icon {
//         color: $color-theme;
//         padding-bottom:0;
//         font-size: 4em;
//         width:100%;
//     }

//     .label {
//         transform: translateY(-0.5em);
//         color: $color-grey-light;
//         // font-size: 1.6em;
//     }
// }

// .drag-container {
//     color: $color-theme;
//     // font-size: 4em;
//     display: grid;
//     grid-gap: calc(var(--gutter) / 2);
//     grid-template-rows: minmax(150px, 1fr);
//     // grid-template-columns: 10px repeat(6, calc(30% - var(--gutter) * 2)) 10px;

//     .drag-item {
//         // width: 30vw;
//         text-align: center;
//     }
// }
// .drag-container:before,
// .drag-container:after {
//     content: '';
// }


// /* The emerging W3C standard
//    that is currently Firefox-only */
// .horizontal-scrollbar {
//     scrollbar-width: thin;
//     scrollbar-color: $color-grey $color-clear;
// }
  
//   /* Works on Chrome/Edge/Safari */
//   .horizontal-scrollbar::-webkit-scrollbar {
//     // width: 12px;
//     height: 6px;
//   }
//   .horizontal-scrollbar::-webkit-scrollbar-track {
//     background: $color-clear;
//   }
//   .horizontal-scrollbar::-webkit-scrollbar-thumb {
//     background-color: $color-grey;
//     border-radius: 20px;
//     border: 3px solid $color-clear;
//   }