
@import "../../styles/colors.scss";
.project-detail{
    .page{
        overflow: hidden;
    }
    .slick-slide {
        transition: transform 500ms;
    }
    .slick-center {
        transform: scale(1.05);
    }
    hr.hr { 
        margin: 0.4em auto;
        margin-bottom: 0.67em;
        width: 8em;
        background-color: $color-theme;
        border: none;
        height: 0.2em;
    }
    .image-frame {
        position: relative;
        padding-top: 56.7%;
        overflow:hidden;
        margin-bottom: 0;
        img {
            padding: 0;
            position: absolute;
            width:100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            display:block;
        }
    }
    .project-heading {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-bottom:4em;
        // background-color: rgb(222, 238, 238);
        // background-color: rgb(155, 219, 219);
        background-color: #efefef;
        // background-color: $color-light;
        // background-color: $color-white;
    }
    .project-year {
        opacity: 0.33;
    }
    .project-gallery {
        margin-top: -4em;
        .inside {
        }
        // transform: translateY(50%);
    }
    .project-description {
    }
    .stack {
        i {
            // justify-content: center;
            margin-right: 10px;
        }
    }
    
}

@media (min-width: $breakpoint-md) {
    .project-detail {
        .project-gallery {
            .inside {
                margin: 0 15%;
                padding: 0;
            }
        }
        .project-description {
            margin: auto 20%;
        }

    }
}