@import "../../styles/colors.scss";

.project-frame-component {
    overflow-anchor:none !important; // Extremely important

    .project-year {
        font-size: 1.8em;
        color: $color-grey-light-extra;
    }
    .project-frame-component-wrapper {
        .left-info {
            .project-stack {
                // text-align: right;
                font-size: 1.5em;
                i {
                    margin-right: 0.2em;
                }
            }
            @media (min-width: $breakpoint-sm) {min-height: 350px;}
            @media (min-width: $breakpoint-md) {min-height: 350px;}
        }
        .right-images {
            margin-top: 2em;
            margin-bottom: 2em;
            .slick-slider {
                line-height: 0;
              }
            .image-frame {
                // width:100%;
                position: relative;
                padding-top: 66.67%;
                overflow:hidden;
                margin-bottom: 0;
                img {
                    padding: 0;
                    position: absolute;
                    width:100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    display:block;
                }
            }
        }
    }

    @media (min-width: $breakpoint-lg) {

        .project-frame-component-wrapper {
            * { min-height: 0; min-width: 0; }
            display:flex;
            min-height: 480px;
            align-items: center;
            .left-info {
                // align-self: center;
                width: 100%;
                flex:1;
                margin-right: 1em;
                // display:flex;
                // height:100%;
                // justify-content: space-around;
                // flex-direction: column;

                h2 {
                    margin-bottom: 0;
                }
                .project-description {
                    margin-top: 1em;
                    margin-bottom: 1em;
                }
                
                // .project-stack {
                //     display: block;
                // }
            }
            .right-images {
                align-self: center;
                width: 100%;
                flex:1;
                margin-left: 1em;
            }
        }
    }
}




// @media (min-width: $breakpoint-lg) {
//     .project-frame-component-outer{
//         min-height: 550px;
//     }
// }