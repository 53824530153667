@import "../../styles/_colors.scss";

@media (max-width: $breakpoint-lg) {

}
.home-component {
    .intro {

        .image{
            // padding-right:20px;
            height:100%;
            align-self: flex-end;
            position: relative;
        }
        .text{
            padding-bottom: 0;
        }
        .mobile-portrait {
            display: block;
            img {
                max-width: 480px;
            }
        }
        #highlight {
            transform: translate(40px, 140px);
            z-index: -50;
            height: 1em;
            width: 70%;
            background-color: $color-theme-light;
        }
        #hello {
            font-size: 1.3em;
            text-align: right;
            padding-right: 3em;
            transform: translateY( 30px);
        }
        .the-name {
            margin-left: inherit;
        }
        .hr { 
            margin-bottom: 40px;
            margin-left: 0.5em;
            margin-top: -1.5em;
            // margin: 0.67em auto;
            width: 90%;
            background-color: $color-theme-light;
            border: none;
            height:1em;
        }
 
    }
    
    @media (min-width: $breakpoint-lg) {
        // .home-component{
            .background {
                background-image: url("../../img/group2_shade.png");
            }
            .intro {
                display: flex;
                align-items: center;
                // height: calc(100vh - 84px);
                height: 100vh;
                min-height:750px;
                .image{
                    flex:2;
                    order: 1;
                    align-self: flex-end;
                    position: relative;
                }
                .text{
                    padding-bottom: 200px;
                    flex:3;
                    order:2;
                }
                .the-name {
                    margin-left: -30px;
                    // padding-bottom: 40px;
                }
                .mobile-portrait {
                    display: none;
                }
              
            }
    
        // }

    }
}