@import "../../styles/_colors.scss";

.title-unit-component {
    .wrapper {
        .left-text {
            padding-top: 0.8em;
            padding-bottom: 1.5em;
        }
        .right-image {
            text-align: center;
            img {
                
                width:75%;
            }
        }
    }
    @media (min-width: $breakpoint-md) {
        .wrapper {
            display: flex;
            align-items: center;
            .left-text {
                margin-top: -1.8em;
                padding-bottom: 2em;
                padding-top: 2em;
                padding-right:2em;
                flex: 6;
            }
            .right-image {
                
                flex: 4;
                img{
                    width:100%;
                }
            }
        }
    }
    
}