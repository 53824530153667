@import "../../styles/_colors.scss";

.projects-component {
    .intro {
        position: static;
        width:100%;
        background-color: $color-white;
        background-image: url("../../img/background4.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .info-wrapper {
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
    
        .intro-image {
            display: none;
        }

        .chevron-arrow {
            height: 0.35em;
        }
        .anchor-link {
            color: $color-theme;
            transition: opacity 500ms;
            // margin-bottom: 2em;
        }
    }
    .hr { 
        margin: 0.67em auto;
        width: 5em;
        background-color: $color-theme;
        border: none;
        height: 0.2em;
    }
    .sort-and-filter {
        color: $color-grey;
        display: block;
        align-items: center;
        justify-content: space-between;
        .filter-icon {
            &:hover {
                background-color: $color-grey-light-ultra;
            }
        }

        .toggle-button-wrapper {
            border: 1px solid #dadce0;
            border-radius: 5px;
            display: flex;
            align-items: center;
            .toggle-button{
                padding-top: 0.25em;
                padding-bottom: 0.25em;
                padding-left: 0.6em;
                padding-right: 0.6em;
                &.left {
                    border-right: 1px solid $color-grey-light-ultra;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                &.highlight {
                    background-color: $color-theme;
                    color: white;
                }
                &:not(.highlight):hover{
                    background-color: $color-grey-light-ultra;
                }
            }

        }
    }        

    @media (min-width: $breakpoint-xl) {
        .intro {
            .info-wrapper {
                min-height: 500px;
                .description {
                    width: 45%;
                }
            }
            // position: relative;

            // .intro-image {
            //     display: block;
            // }
            // .info-wrapper{
            //     position:absolute;
            //     top:0;
            //     left:0;            
            //     width:100%;
            //     height: 100%;
            //     .description {
            //         margin-top:  1em;
            //         margin-left: 10em;
            //         margin-right: 10em;
            //         width: 45%;
            //     }
            // }
        }
    }
    @media (min-width: $breakpoint-xxl) {
        .intro {
            .info-wrapper {
                min-height: 700px;
            }
        }
    }
    @media (min-width: $breakpoint-xxxl) {
        .intro {
            .info-wrapper {
                min-height: 900px;
            }
        }
    }
    @media (min-width: $breakpoint-xxxxl) {
        .intro {
            .info-wrapper {
                min-height: 1200px;
            }
        }
    }
    @media (min-width: $breakpoint-lg) {
        .sort-and-filter {
            display:flex;
            align-items: flex-end;
            div {
                padding-bottom: 0em;
                padding-top: 0em;
            }
        }


    }
    @media (min-width: $breakpoint-sm) {
        .info-wrapper .description {
            width: 70%;
        }
    }
    @media (min-width: $breakpoint-md) {
        .info-wrapper .description {
            width: 60%;
        }
    }
}
