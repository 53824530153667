$font-default: neue-haas-grotesk-text,sans-serif;
$font-default-heading: neue-haas-grotesk-display, sans-serif;
$font-display: neue-haas-grotesk-display, sans-serif;

// $font-display: condor, sans-serif;
// $font-display: quiche-sans, sans-serif;
$font-decoration: fairwater-deco-serif, sans-serif;
// $font-display: fairwater-deco-serif, sans-serif;

// font-family: fairwater-open-serif, sans-serif;
// font-weight: 400;
// font-style: normal;

$color-theme-light: #C7ECEC;
$color-grey: #767676;
$color-grey-light: #86868b;
$color-grey-light-more: #959597;
$color-grey-light-extra: #D5D5D7;
$color-grey-light-ultra: #e6e6e6;
$color-dark: #1D1D1F;
$color-extra-dark: #000000;
$color-theme: #9BE3E1;
// $color-theme-dark: #59ccc8;
$color-theme-dark: #55d4d2; // new, experimental
$color-light: #F6F6F7;
$color-white: #fbfbfd;

$color-green:#5FD490;
$color-theme-contrast: #da6d88;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1452px;
$breakpoint-xxxl: 2160px;
$breakpoint-xxxxl: 3040px;
// $color-light: #f9f9fa;
$color-clear: rgba(0,0,0,0);
